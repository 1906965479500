<template>
  <div class="main" v-if="porder_freight">
    <danGeShangPingZhuangXiangXinXi></danGeShangPingZhuangXiangXinXi>
    <!-- {{ porder_detail }} -->
    <div class="huoyunTable">
      <div class="head tbc">
        <span>{{ $fanyi("发送方式") }}</span>
        <span>{{ $fanyi("物流单号") }}</span>
        <span>{{ $fanyi("计费数据") }}</span>
        <span>{{ $fanyi("费用(€)") }}</span>
      </div>
      <div class="body">
        <div class="opt tbc">
          <span>{{ huoYunData.logistics }}</span>
          <span>{{ huoYunData.express_no }}</span>
          <span>{{ huoYunData.billing_parameter }}</span>
          <span>{{ huoYunData.amount }} €</span>
        </div>
      </div>
    </div>
    <div class="wuliu" v-if="false">
      <div class="ohead">
        <div class="spanishModeOfLogistics ohead">
          {{ $fanyi("西班牙物流方式") }}
        </div>
        <div class="spanishLogisticsTrackingNo ohead">
          {{ $fanyi("西班牙物流单号") }}
        </div>
        <div class="transmissionTime ohead">
          {{ $fanyi("发送时间") }}
        </div>
      </div>

      <div
        class="obody"
        v-for="(porder_foreign_express,
        porder_foreign_expressIndex) in porder_foreign_express"
        :key="porder_foreign_expressIndex"
        v-show="porder_foreign_express"
      >
        <div class="spanishModeOfLogistics obody">
          <!-- {{ $fanyi("西班牙物流方式") }} -->
          {{ porder_foreign_express.foreign_express }}
        </div>
        <div class="spanishLogisticsTrackingNo obody">
          <!-- {{ $fanyi("西班牙物流单号") }} -->
          {{ porder_foreign_express.foreign_express_no }}
        </div>
        <div class="transmissionTime obody">
          <!-- {{ $fanyi("发送时间") }} -->
          {{ porder_foreign_express.created_at }}
        </div>
      </div>
      <div class="obody" v-show="!porder_foreign_express">
        <div class="spanishModeOfLogistics obody">
          <!-- {{ $fanyi("西班牙物流方式") }} -->
          ————
        </div>
        <div class="spanishLogisticsTrackingNo obody">
          <!-- {{ $fanyi("西班牙物流单号") }} -->
          ————
        </div>
        <div class="transmissionTime obody">
          <!-- {{ $fanyi("发送时间") }} -->
          ————
        </div>
      </div>
    </div>
    <div class="goodsTable">
      <div class="head">
        <!-- 箱号 -->
        <span class="sn tdc">{{ $fanyi("箱号") }}</span>
        <!-- 实际重量 -->
        <span class="weight tdc">{{ $fanyi("实际重量") }}(kg)</span>
        <!-- 长 -->
        <span class="long tdc">{{ $fanyi("长") }}(cm)</span>
        <!-- 宽 -->
        <span class="wide tdc">{{ $fanyi("宽") }}(cm)</span>
        <!-- 高 -->
        <span class="high tdc">{{ $fanyi("高") }}(cm)</span>
        <!-- 体积 -->
        <span class="volume tdc">{{ $fanyi("体积") }}(m³)</span>
        <!-- 物流单号 -->
        <!-- <span class="trackingNumber tdc">{{ $fanyi("物流单号") }}</span> -->
      </div>
      <div class="body">
        <div class="bodyOpt" v-if="porder_freight.length == 0">
          <div class="optBody notHaveData">{{ $fanyi("暂无数据") }}</div>
        </div>
        <div class="bodyOpt" v-else>
          <div
            class="optBody"
            v-for="(orderItem, orderIndex) in porder_freight"
            :key="orderIndex"
            @click="openOne(orderItem)"
          >
            <!-- 箱号 -->
            <span class="sn tdc con">
              <span class="box">
                {{ orderItem.number }}
              </span>
            </span>
            <!-- 实际重量 -->
            <span class="weight tdc">{{ orderItem.weight }}</span>
            <!-- 长 -->
            <span class="long tdc">{{ orderItem.length }}</span>
            <!-- 宽 -->
            <span class="wide tdc">{{ orderItem.width }}</span>
            <!-- 高 -->
            <span class="high tdc">{{ orderItem.height }} </span>
            <!-- 体积 -->
            <span class="volume tdc">{{
              $fun.ceil(
                (orderItem.length * orderItem.width * orderItem.height) /
                  1000000,
                3
              )
            }}</span>
            <!-- 物流单号 -->
            <!-- <span class="trackingNumber tdc">{{
              $parent.datas.express_no
            }}</span> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import danGeShangPingZhuangXiangXinXi from "./danGeShangPingZhuangXiangXinXi.vue";
export default {
  data() {
    return {
      oneGoodsPackingQuantity: false,
      oneData: {},
    };
  },
  components: {
    danGeShangPingZhuangXiangXinXi,
  },
  computed: {
    porder_freight() {
      if (
        this.$parent.datas.result &&
        this.$parent.datas.result.porder_freight
      ) {
        return this.$parent.datas.result.porder_freight;
      }
    },
    porder_foreign_express() {
      if (
        this.$parent.datas.result &&
        this.$parent.datas.result.porder_foreign_express
      ) {
        return this.$parent.datas.result.porder_foreign_express;
      }
    },
    huoYunData() {
      if (this.$parent.datas.result) {
        return {
          logistics: this.$parent.datas.result.logistics_name,
          express_no: this.$parent.datas.result.express_no,
          billing_parameter: this.$parent.datas.result.billing_parameter,
          // 2023/02/02 15:37 这里取国际物流费用
          amount: this.$fun.EURNumSegmentation(
            this.$parent.datas.result.logistics_price
          ),
        };
      }
    },
  },
  created() {},
  methods: {
    openOne(data) {
      this.oneGoodsPackingQuantity = true;
      this.oneData = data;
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "../../../../../../../css/mixin.scss";

.main {
  width: $pageWidth;
  margin: 30px auto 20px;
  background: #fefbf4;
  border: 1px solid #ffa724;
  padding: 30px;

  .huoyunTable {
    margin-bottom: 20px;

    .head {
      background: rgba($color: #47bd9b, $alpha: 0.4);
      height: 56px;

      &.tbc {
        span {
          border: none !important;
        }
      }
    }

    .body {
      background-color: white;
      border: 1px solid #ededed;

      .opt {
        height: 60px;
      }
    }

    .tbc {
      display: flex;
      align-items: center;

      span {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        text-align: center;
        border-left: #ededed solid 1px;
        border-bottom: #ededed solid 1px;
      }
    }
  }

  .wuliu {
    margin-bottom: 30px;

    > .ohead {
      background: rgba($color: #4770bd, $alpha: 0.3);
      height: 56px;
      display: flex;

      > div {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
      }
    }

    > .obody {
      background-color: white;
      display: flex;
      border-right: solid 1px #ededed;

      > div {
        flex: 1;
        height: 60px;
        display: flex;
        font-size: 14px;
        justify-content: center;
        align-items: center;
        border: solid #ededed 1px;
        border-top: none;
        border-right: none;
      }
    }
  }

  .goodsTable {
    .head {
      height: 56px;
      background-color: rgba($color: #ffa724, $alpha: 0.4);
      display: flex;
      align-items: center;
      font-weight: 600;
    }

    .body {
      .bodyOpt {
        font-weight: 600;

        .optHead {
          height: 60px;
          display: flex;
          align-items: center;

          .title {
            font-size: 14px;
            color: #888888;
            line-height: 20px;
            margin-left: 20px;
          }

          .titlebody {
            font-size: 14px;
            color: #000000;
            line-height: 20px;
          }

          .downIcon {
            transition: 0.3s;
            line-height: 20px;
            transform: rotate(90deg);
            margin-left: 10px;

            &.open {
              transform: rotate(270deg);
            }
          }
        }

        .optBody {
          height: 60px;
          background: #fff;
          display: flex;
          align-items: center;
          border: solid 1px #ededed;
          border-bottom: none;
          margin-bottom: -1px;
          cursor: pointer;

          &:hover {
            background-color: #fafafa;

            .box {
              display: block;
              width: 64px;
              height: 28px;
              background: #ffa724;
              color: white;
              text-align: center;
              line-height: 28px;
            }
          }

          &.notHaveData {
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: default;
          }

          .tdc {
            border-right: solid 1px #ededed;
            border-bottom: solid 1px #ededed;

            &:last-child {
              border-right: none;
            }
          }
        }
      }
    }

    .tdc {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      &.sn {
        flex: 0 0 150px;

        &.con {
          .box {
            display: block;
            width: 64px;
            height: 28px;
            border: solid 1px #ffa724;
            color: #ffa724;
            text-align: center;
            line-height: 28px;
          }
        }
      }

      &.trackingNumber {
        flex: 0 0 313px;
      }
    }
  }
}
</style>
