<template>
  <el-dialog :title="$fanyi('选择优惠券或代金券')" :visible.sync="$parent.CouponsShow" width="443px">
    <div class="Con" v-if="arrType.length != 0">
      <!-- 代金券 -->
      <div class="singleCoupon preferential">
        <!-- 如果没达到使用条件就让它点击 -->
        <div v-for="(item, index) in arrType" :key="index"
          v-show="$fun.ceil($parent.orderData.zongJia) > item.restrictions" class="radial-gradient"
          :class="{ active: active.arrType == index }" @click="
            $fun.ceil($parent.orderData.zongJia) < item.restrictions
              ? $message.error($fanyi('未达到使用条件'))
              : active.arrType == index
                ? (active.arrType = -1)
                : (active.arrType = index)
          ">
          <div class="title" v-bind:class="{
            notUser: $fun.ceil($parent.orderData.zongJia) < item.restrictions,
          }">
            <span> {{ $fanyi("代金券") }}</span>
          </div>
          <div class="optCon" v-bind:class="{
            notUser: $fun.ceil($parent.orderData.zongJia) < item.restrictions,
          }">
            <p class="title">{{ $fanyi("编号") }}：{{ item.number }}</p>
            <p class="price">{{ item.free_kin }}{{ $fanyi("欧元") }}</p>
            <p class="condition">
              {{ $fanyi("订单金额必须大于") }}
              {{ item.restrictions }}
            </p>
            <p class="time">
              {{ $fanyi("有效期") }}:
              {{ item.free_datetime_start.substr(0, 10) }} /
              {{ item.free_datetime_end.substr(0, 10) }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="notHaveCoupons" v-else>{{ $fanyi("暂无优惠券") }}</div>
    <div class="footer">
      <div class="btnGroup">
        <button @click="setData()">{{ $fanyi("确认") }}</button>
        <button class="quXiao" @click="$parent.CouponsShow = false">
          {{ $fanyi("取消") }}
        </button>
      </div>
      <div class="prompt">
        {{ $fanyi("注意:同一订单只能使用同一类型的优惠券。") }}
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      form: {
        page: 1,
        pageSize: 999,
      },
      active: {
        arrType: -1,
      },
      datas: [],
      arrType: [],
    };
  },
  components: {},
  created() {
    this.getData();
  },
  methods: {
    // 取消使用优惠券
    cancelCoupons() {
      this.active.arrType = -1;
      this.setData();
    },
    // 保存数据
    setData() {
      let ids = [];
      if (this.active.arrType != -1) {
        ids.push(this.arrType[this.active.arrType].id);
      }

      this.$parent.CouponsData.ids = ids.join(",");
      this.$parent.CouponsShow = false;
      this.$parent.youHuiQuanShiYong = true;
      // 如果两种优惠券都没有使用就取消多选框选中
      if (this.active.arrType == -1) {
        this.$parent.youhuiSmall = false;
        this.$parent.youHuiQuanShiYong = false;
        this.$parent.discountAmount = 0;
      } else {
        console.log(this.active);
        console.log(this.arrType[this.active.arrType]);
        this.$parent.youhuiSmall = true;
        this.$parent.discountAmount =
          this.arrType[this.active.arrType].free_kin;
      }
    },
    // 获取数据
    getData() {
      this.$api
        .couponGet(this.form)
        .then((res) => {
          this.datas = res.data;
          this.lists = {};
          this.arrType = res.data.data.filter((item) => item.type == 30);
          // this.arrData = res.data.data.filter((item) => item.type != 30);
          this.$forceUpdate();
        })
        .catch((err) => { });
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
/deep/.el-dialog {
  border-radius: 6px;

  .el-dialog__body {
    padding: 29px 35px;
  }

  .Con {
    margin-bottom: 10px;
    width: 100%;
    height: 343px;
    overflow-y: auto;
    overflow-x: hidden;

    // 单张优惠券
    .singleCoupon {
      .radial-gradient {
        cursor: pointer;
        margin-right: 0;
        width: 362px;
        margin-bottom: 23.4px;
        height: 147px;
        position: relative;
        display: flex;
        align-items: center;
        box-shadow: 0px 0px 10px 0px rgba(61, 61, 61, 0.1);

        &::after {
          content: "";
          position: absolute;
          top: 0px;
          bottom: 0px;
          left: -5px;
          width: 10px;
          height: 100%;
          background: radial-gradient(circle, #fff, #fff 4px, transparent 5px);
          background-size: 10px 10px;
        }

        &::before {
          content: "";
          position: absolute;
          top: 0px;
          bottom: 0px;
          right: -5px;
          width: 10px;
          height: 100%;
          background: radial-gradient(circle, #fff, #fff 4px, transparent 5px);
          background-size: 10px 10px;
        }

        >.title {
          width: 55.25px;
          height: 128.7px;
          margin: 9px 21.45px 9px 16.25px;
          background: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;

          span {
            writing-mode: vertical-rl;
            font-weight: bold;
            font-size: 31px;
            text-align: center;
            transform: rotate(180deg);
          }
        }

        .optCon {
          >.title {
            height: 20px;
            font-size: 12px;
            // color: #ffffff;
            line-height: 12px;
            margin-bottom: 13px;
          }

          .condition {
            font-size: 12px;
          }

          .price {
            font-size: 31.2px;
            font-weight: bold;
            line-height: 31.2px;
            margin-bottom: 20px;
          }

          .time {
            height: 12px;
            font-size: 12px;
            line-height: 16px;
          }
        }
      }

      &.preferential {
        .radial-gradient {
          >.title {
            border: 2px solid #5a81fa;

            span {
              color: #5a81fa;
            }
          }

          &.active {
            background-color: #5a81fa;

            >.title {
              background: #ffffff;
              border: 2px solid #5a81fa;

              span {
                color: #5a81fa;
              }
            }

            .optCon {
              >.title {
                color: #ffffff;
              }

              .price {
                color: #ffffff;
              }

              .condition {
                color: #ffffff;
              }

              .time {
                color: #ffffff;
              }
            }
          }
        }
      }

      &.kims {
        .radial-gradient {
          >.title {
            border: 2px solid #ffa624;

            span {
              color: #ffa624;
            }
          }
        }

        .radial-gradient {
          &.active {
            background-color: #ffa624;

            >.title {
              background: #ffffff;
              border: 2px solid #ffa624;

              span {
                color: #ffa624;
              }
            }

            .optCon {
              >.title {
                color: #ffffff;
              }

              .price {
                color: #ffffff;
              }

              .condition {
                color: #ffffff;
              }

              .time {
                color: #ffffff;
              }
            }
          }
        }
      }
    }
  }

  .footer {
    .prompt {
      font-size: 14px;
      font-weight: 400;
      color: #5c5c5c;
      line-height: 19px;
      text-align: center;
    }

    .btnGroup {
      margin-bottom: 20.7px;
      text-align: center;

      button {
        width: 112px;
        height: 37.7px;
        background: #1e2997;
        border-radius: 4px;
        color: #ffffff;
        font-size: 16px;

        &.quXiao {
          background-color: white;
          color: #1e2997;
          border: 1px solid #3a4de4;
          margin-left: 20px;
        }
      }
    }
  }

  .notHaveCoupons {
    height: 300px;
    font-size: 25px;
    width: 100%;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.notUser {
  opacity: 0.3 !important;
}
</style>
