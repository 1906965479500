<template>
  <div class="moduleBody">
    <div class="row1">
      <div class="customsClearanceMethod opt">
        <h3>{{ $fanyi("通关方式") }}</h3>
        <div class="inputBox">
          <template v-if="$parent.form.personal_clearance == 'yes'"
            >{{ $fanyi("RAKUMART代通关") }}
          </template>
          <template v-if="$parent.form.personal_clearance == 'no'"
            >{{ $fanyi("个人通关") }}
          </template>
        </div>
      </div>
      <div class="modeOfTransport opt">
        <h3>{{ $fanyi("运货方式") }}</h3>
        <div class="inputBox">
          {{ $parent.form.logistics }}
        </div>
      </div>
      <div class="serviceOrSpecialRequest opt">
        <h3>{{ $fanyi("服务或特殊要求") }}</h3>
        <div class="textBox">{{ $parent.form.client_remark }}</div>
      </div>
    </div>
    <div class="row2">
      <div class="prompt">
        <img :src="require('@/assets/icon/tishi.png')" alt="" />
        <span>{{
          $fanyi("如果您需要更改运输信息或运输方式，请与代理联系。")
        }}</span>
      </div>
      <div class="contactUs">
        <button @click="$fun.clickJs()">{{ $fanyi("与代理联系") }}</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  components: {},
  computed: {},
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.moduleBody {
  width: 1400px;
  margin: 0 auto;
  .row1 {
    display: flex;
    margin-bottom: 30px;
    .opt {
      height: 187px;
      background: #ffffff;
      border: 1px solid #e8e8e8;
      border-radius: 6px;
      padding: 25px 34px;
      margin-right: 26px;
      &:last-child {
        margin-right: 0;
      }
      h3 {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 25px;
      }
      .inputBox {
        width: 217px;
        height: 56px;
        background: #f7f7f7;
        border: 1px solid #dde0e6;
        border-radius: 4px;
        text-align: center;
        font-size: 14px;
        color: #2c2c2c;
        line-height: 56px;
      }
      .textBox {
        height: 83px;
        background: #ffffff;
        border: 1px solid #dde0e6;
        border-radius: 4px;
        font-size: 14px;
        padding: 10px;
        overflow-y: auto;
      }
    }
    .customsClearanceMethod {
      width: 320px;
    }
    .modeOfTransport {
      width: 320px;
    }
    .serviceOrSpecialRequest {
      flex: 1;
    }
  }
  .row2 {
    height: 102px;
    background: #ffffff;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 31px;
    .prompt {
      display: flex;
      align-items: center;
      img {
        width: 60px;
        height: 60px;
        margin-right: 8px;
      }
      span {
        font-size: 17px;
      }
    }
    .contactUs {
      button {
        width: 247px;
        height: 56px;
        border: 2px solid #6380f4;
        border-radius: 6px;
        transition: 0.3s;
        font-size: 15px;
        font-weight: 600;
        color: #6380f4;
        &:hover {
          background-color: #6380f4;
          color: white;
        }
      }
    }
  }
}
</style>
