<template>
  <div class="main">
    <!-- 配送单详情提出配送单时弹出 -->
    <Dialog ref="dia3" :config="config">
      <div>
        <p>
          Estimado/a {{ $store.state.userInfo.user_name }}, actualmente se
          encuentra en la página de envío de solicitudes de entrega,
        </p>
        <p>
          Puede editar y ajustar la cantidad de envío y las opciones del
          producto en la página actual.Si tiene otros asuntos que necesitan
          nuestra atención, puede apuntarlo en 【Comentarios】 del albarán de
          entrega. Procesaremos de acuerdo con sus comentarios.
        </p>
        <p>
          La entrega del producto requiere información del importador y del
          destinatario, asegúrese de completar con precisión. (La información
          del importador de despacho de aduana será permisivamente la empresa
          española-RAKUMART) Después de confirmar que no hay problema, haga clic
          en el botón 【Enviar un albarán de entrega】 para enviar una orden de
          entrega.
        </p>
        <p>
          Después de recibir su solicitud de entrega y empacar la caja, le
          enviaremos los detalles de la tarifa.
        </p>
        <p>
          En ese momento, recibirá un correo electrónico de cotización con
          detalles específicos de la tarifa. Puede verificar la cotización
          específica y los detalles del embalaje en 【Mi cuenta】-【Continuar
          con el pago】.
        </p>
      </div>
    </Dialog>
    <!-- 配送单详情待付款时弹出 -->
    <Dialog ref="dia4" :config="config">
      <div>
        <p>
          Estimado/a {{ $store.state.userInfo.user_name }}, actualmente está
          esperando la página de detalles para pagar la orden de entrega,
        </p>
        <p>
          Puede ver la información de cotización de tarifas internacionales
          específicas en la página actual. Después de confirmar que no hay
          problema, puede hacer clic en el botón 【Ir a pagar】 para pagarlo.
          Arreglaremos la entrega logística después de confirmar el recibo. Y el
          estado de logística de seguimiento se le enviará por correo.
        </p>
        <p>
          Si necesita ajustar los productos enviados, envíe los detalles para
          que se lo ajusten al vendedor. Le volveremos a cotizar después de
          ajustarlo de acuerdo con sus necesidades de modificación. (Debido a
          que el ajuste directo del cliente hará que los datos de embalaje
          reales del producto sean anormales, limitamos la función del ajuste
          directo del pedido de entrega del cliente, perdóneme).
        </p>
      </div>
    </Dialog>
  </div>
</template>
<script>
import Dialog from "../../../../../../../components/public/Dialog.vue";

export default {
  data() {
    return {
      config: {
        top: "20vh",
        width: "800px",
        title: "Atención",
        btnTxt: ["Cerrar"],
      },
    };
  },
  components: { Dialog },
  computed: {
    status_name() {
      return this.$parent.datas.result.status_name;
    },
  },
  mounted() {
    if (this.$route.query.id) {
      this.$refs.dia3.open("", () => {}, "");
    }
  },
  methods: {
    openOrderDia() {
      //console.log(this.status_name);
      // if (this.status_name == "等待付款") {
      //   this.$refs.dia4.open("", () => {}, "");
      // }
      this.$forceUpdate();
    },
  },
};
</script>
<style lang='scss' scoped='scoped'>
@import "../../../../../../../css/mixin.scss";
.main {
}
p {
  font-size: 16px;
  text-indent: 1cm;
  margin-bottom: 10px;
}
</style>