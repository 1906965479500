<template>
  <div>
    <div class="addUserInformation">
      <div class="setShippingAddress modelBox">
        <div class="modelTitle">{{ $fanyi("收货地址") }}</div>
        <div class="addInfo">
          <div class="addInfocolumns">
            <div class="addInfoRow">
              <div class="addInfoTd">
                <div class="addInfoTdTitle">{{ $fanyi("收件人") }}</div>
                <div class="addInfoTdBody">
                  {{ userActiveAdd.consignee.contacts }}
                </div>
              </div>
            </div>
          </div>
          <div class="addInfocolumns">
            <div class="addInfoRow">
              <div class="addInfoTd">
                <div class="addInfoTdTitle">{{ $fanyi("地址") }}</div>
                <div class="addInfoTdBody textareaBox">
                  {{ userActiveAdd.consignee.address }}
                </div>
              </div>
            </div>
            <div class="addInfoRow">
              <div class="addInfoTd">
                <div class="addInfoTdTitle">{{ $fanyi("邮编") }}</div>
                <div class="addInfoTdBody">
                  <span> {{ userActiveAdd.consignee.zip_code }}</span>
                </div>
              </div>
              <div class="addInfoTd" style="flex:0 0 156px">
                <div class="addInfoTdTitle">
                  {{ $fanyi("城市") }}
                </div>
                <div class="addInfoTdBody">
                  <span>{{ userActiveAdd.consignee.city }}</span>
                </div>
              </div>
              <div class="addInfoTd">
                <div class="addInfoTdTitle">{{ $fanyi("国家") }}</div>
                <div class="addInfoTdBody">
                  <span> {{ userActiveAdd.consignee.country }}</span>
                </div>
              </div>
            </div>
            <div class="addInfoRow">
              <div class="addInfoTd" style="flex: 0 0 133px">
                <div class="addInfoTdTitle">{{ $fanyi("电话") }}</div>
                <div class="addInfoTdBody">
                  {{ userActiveAdd.consignee.mobile }}
                </div>
              </div>
              <div class="addInfoTd">
                <div class="addInfoTdTitle">{{ $fanyi("邮件箱") }}</div>
                <div class="addInfoTdBody">
                  {{ userActiveAdd.consignee.email }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="priceBox modelBox">
        <h1>
          {{ $fanyi("产品支出总额") }}:
          <font class="price">
            {{ $fun.EURNumSegmentation($fun.ceil($parent.orderData.zongJia)) }}€
          </font>
        </h1>
        <div class="priceOrder">
          <h2>{{ $fanyi("费用明细") }}</h2>
          <p>
            {{ $fanyi("国际物流费用") }}:<font class="otherPrice">
              {{ $fun.EURNumSegmentation(datas.logistics_price) }} €</font
            >
          </p>
          <p>
            {{ $fanyi("西班牙物流费用") }}:<font class="otherPrice">
              {{ $fun.EURNumSegmentation(datas.foreign_express_price) }} €</font
            >
          </p>
          <p>
            {{ $fanyi("其他费用") }}:<font class="otherPrice">
              {{ $fun.EURNumSegmentation(datas.other_price) }} €</font
            >
          </p>
          <!-- 反倾销税 -->
          <p>
            {{ $fanyi("关税") }}:<font class="otherPrice">
              {{ $fun.EURNumSegmentation(datas.clearance_price) }} €</font
            >
          </p>
          <p>
            {{ $fanyi("增值税") }}:<font class="otherPrice">
              {{ $fun.EURNumSegmentation(datas.added_price) }} €</font
            >
          </p>

          <p>
            Cuota.R.E(5.2%):<font class="otherPrice">
              {{ $fun.EURNumSegmentation(datas.personal_added_price) }} €</font
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Dialog from "../../../../../../../components/public/Dialog.vue";
import Europess from "../../../../../../../api/wangZhi";
let Europe = Europess + "/client/";
import axios from "axios";
import { DOWLOAD_FILE } from "@/utlis/dowload";
export default {
  data() {
    return {
      fullscreenLoading: false,
      showAddressList: false,
      config: {
        top: "20vh",
        width: "1000px",
        title: this.$fanyi("收货地址详情"),
        btnTxt: [this.$fanyi("关闭")],
      },
    };
  },
  components: { Dialog },
  computed: {
    datas() {
      return this.$parent.datas.result;
    },
    gerenshui() {
      return this.$parent.gerenshui;
    },
    userActiveAdd() {
      return this.$parent.userActiveAdd;
    },
    userActiveAddName() {
      return this.$parent.userActiveAddName;
    },
    form() {
      return this.$parent.form;
    },
    pageStatus() {
      return this.$parent.pageStatus;
    },
    useraddLists() {
      return this.$parent.useraddLists;
    },
  },
  created() {},
  methods: {
    getFapiao() {
      this.fullscreenLoading = true;
      let params = {
        porder_sn: this.$parent.datas.porder_sn,
      };

      axios
        .post(Europe + "download.porderProformaInvoice", params, {
          responseType: "blob", // 1.首先设置responseType对象格式为 blob:
        })
        .then((res) => {
          this.fullscreenLoading = false;

          if (res.size < 200) {
            return this.$message.warning(this.$fanyi("操作失败"));
          }

          let blob = new Blob([res], {
            type: "application/vnd.ms-excel",
          });
          // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
          let url = window.URL.createObjectURL(blob); // 3.创建一个临时的url指向blob对象

          // 4.创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
          let a = document.createElement("a");
          a.href = url;
          a.download = "INVOICE_" + this.$parent.datas.porder_sn + ".xlsx";
          a.click();
          // 5.释放这个临时的对象url
          window.URL.revokeObjectURL(url);
          this.diaShow = !this.diaShow;
        })
        .catch((error) => {
          this.$message(this.$fanyi(error.message));
        });
    },
    downloadFile(url, name = "imagen") {
      var a = document.createElement("a");
      a.setAttribute("href", url);
      a.setAttribute("download", name);
      let clickEvent = document.createEvent("MouseEvents");
      clickEvent.initEvent("click", true, true);
      a.dispatchEvent(clickEvent);
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.addUserInformation {
  display: flex;
  width: 1400px;
  margin: 0 auto 25px;
  .modelBox {
    height: 419px;
    background: #ffffff;
    border: 1px solid #e8e8e8;
    border-radius: 6px;
    padding: 30px;
    .modelTitle {
      font-size: 18px;
      font-weight: 600;
      line-height: 1;
      margin-bottom: 28px;
    }
    &.setShippingAddress {
      margin-right: 65px;
      flex: 0 0 845px;
      .addInfo {
        display: flex;
        align-items: flex-start;
        .addInfocolumns {
          margin-right: 24px;
          &:last-child {
            margin-right: 0;
          }
          flex: 1;
          .addInfoRow {
            display: flex;
            margin-bottom: 15px;

            .addInfoTd {
              flex: 1;
              margin-left: 17px;
              &:first-child {
                margin-left: 0;
              }
              .addInfoTdTitle {
                font-size: 12px;
                font-weight: 400;
                line-height: 1;
                margin-bottom: 10px;
              }
              .addInfoTdBody {
                height: 56px;
                background: #ffffff;
                border: 1px solid #dde0e6;
                border-radius: 4px;
                padding: 20px 17px;

                font-size: 14px;
                line-height: 1;
                font-weight: 400;
                span {
                  @extend .text-overflow-one;
                }
                &.textareaBox {
                  height: 97px;
                  overflow: auto;
                }
              }
            }
          }
        }
      }
    }
    &.priceBox {
      flex: 1;
      padding: 31px 35px;
      h1 {
        font-size: 18px;
        font-weight: 600;
        color: #2f2f2f;
        line-height: 1;
        margin-bottom: 23px;
        .price {
          color: #ffaf3e;
        }
      }
      .priceOrder {
        > * {
          padding: 15px 0;
        }
        h2 {
          font-size: 16px;
          font-weight: 500;
          padding: 0 0 15px;
        }
        p {
          font-weight: 400;
          display: flex;
          font-size: 14px;
          line-height: 14px;
          color: #868686;
          justify-content: space-between;
          align-items: center;
          border-bottom: solid 1px #e8e8e8;
          &:last-child {
            border-bottom: none;
          }
          .otherPrice {
            color: #2f2f2f;
            font-weight: bold;
          }
        }
      }
    }
  }
}
</style>
